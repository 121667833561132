// Import Routes
import routes from './routes.ts';
// Import Store
import store from './store.ts';

import { Event, recordEvent } from "./analytics.ts";
import { device, initApp } from "./app.ts";

export const f7params = {
    name: "Coherence", // App name
    theme: "md", // force Material Theme
    darkMode: true,
    colors: {
        primary: '#7431fa',   // specify primary color theme
    },
    store: store,
    routes: routes,
    // Input settings
    input: {
        scrollIntoViewOnFocus: device.native,
        scrollIntoViewCentered: device.native
    },
    // Capacitor Statusbar settings
    statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: true
    },
    view: {
        browserHistory: device.web // only in
    },
    on: {
        init: function () {
            const f7 = this; // eslint-disable-line
            // install error handler to log errors
            initApp(f7 as any); // eslint-disable-line
        },
        pageTabShow: function (tabEl: HTMLElement) {
            // hack to get all pages views
            const newUrl = tabEl.dataset.name; // use data-name
            if (newUrl) {
                recordEvent(Event.PageView, {
                    page_location: `/${newUrl}`
                });
            }
        },
        routeChange: function (newRoute: any) { // eslint-disable-line
            const newUrl = newRoute.url || newRoute.path;
            if (newUrl) {
                recordEvent(Event.PageView, {
                    page_location: newUrl
                });
            }
        },
        connection: function (isOnline: boolean) {
            if (isOnline) {
                console.log("app is online now"); // TODO record event
            } else {
                console.log("app is offline now");
            }
        }
    }
};

