import { eachDayOfInterval, endOfWeek, format, formatDuration as formatDurationFn, intervalToDuration, startOfWeek } from "date-fns";

/** Monday 2024-01-01 */
export const MONDAY_JAN_1_2024 = new Date(2024, 0, 1);

/**
 * Format duration in plain english (or other languages)
 * 
 * @param ms the number of milliseconds
 * @returns a string like '3 days', '1 week'...etc
 */
export const formatDuration = (ms: number): string => {
    const duration = intervalToDuration({ start: 0, end: ms });
    return formatDurationFn(duration);
}

/**
 * Get the days of the week names in the current language
 * 
 * @param startDate the date (day of week) to start from
 * @returns an array of strings like ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
 */
export const getWeekDaysNames = (startDate: null | Date = null): string[] => {
    let endDate;
    if (!startDate) {
        startDate = startOfWeek(MONDAY_JAN_1_2024); // TODO check bug other locales beginning of year
        endDate = endOfWeek(startDate); // get the last day of the week for the current locale
    } else {
        endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6); // get the last day of the week
    }
    const daysOfWeek = eachDayOfInterval({ start: startDate, end: endDate });
    return daysOfWeek.map((day) => getDayName(day));
}

/**
 * Get the name of a day in the current locale
 * @param day 
 * @returns 
 */
export const getDayName = (day = new Date()): string => {
    return format(day, 'EEEE');
}


const getTimeDetails = (distancems: number) => {
    // always use a positive distance
    const distance = distancems < 0 ? distancems * -1 : distancems;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return [days, hours, minutes, seconds]
}

/**
 * 
 * @param ms the number of milliseconds
 * @returns a string like hh:mm -> '00:01' or dd:hh:mm -> '01:23:59'
 */
export const getTimerLikeDurationText = (ms: number): string => {
    const [days, hours, minutes, seconds] = getTimeDetails(ms);
    const fmt = (v: number) => `${v < 10 ? "0" + v : v}`;
    const text = `${days > 0 ? fmt(days) + ':' : ''}`
        + `${hours > 0 || days > 0 ? fmt(hours) + ':' : ''}`
        + `${fmt(minutes)}:${fmt(seconds)}`;

    // add a + sign when negative duration
    return ms < 0 ? `+${text}` : text;
}


