<script>
  import { f7ready, Icon, List, ListItem, Page } from "framework7-svelte";
  import { onMount } from "svelte";
  import { isEnabled, updateConsent } from "../../js/analytics.ts";
  import { browseURL } from "../../js/app.ts";
  import { translate as tr } from "../../js/i18n.ts";
  import { isDebugUser } from "../../js/settings.ts";

  let analyticsEnabled = isEnabled();
  let smartSelectEl;

  const downloadData = () => {
    // dump localstorage contents
    const settings_obj = {};
    for (const key in localStorage) {
      settings_obj[key] = localStorage[key];
    }

    const data = JSON.stringify(settings_obj, null, 2);
    const file = new Blob([data], { type: "application/json" });
    const a = document.createElement("a");
    const url = URL.createObjectURL(file);
    a.href = url;
    a.classList.add("external");
    a.download = "coherence-data.json";
    document.body.appendChild(a);
    a.click();
    // remove data
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  };

  onMount(() => {
    f7ready(() => {
      // update consent
      const smartSelect = smartSelectEl.smartSelectInstance();
      smartSelect.setValue(analyticsEnabled ? "true" : "false");
      smartSelect.on("close", () => {
        const enabled = smartSelect.getValue();
        analyticsEnabled = enabled === "true";
        updateConsent(analyticsEnabled);
      });
    });
  });
</script>

<Page name="privacy">
  <div class="navbar">
    <div class="navbar-inner sliding">
      <div class="left">
        <a href={"#"} class="link back">
          <i class="icon icon-back"></i>
          <span class="if-not-md">Back</span>
        </a>
      </div>
      <div class="title">{tr("privacy.title")}</div>
    </div>
  </div>

  <div class="block">
    {tr("privacy.description")}
  </div>
  <List>
    <li>
      <a
        href={"#"}
        class="item-link item-content"
        on:click={() =>
          browseURL(
            "https://coherence-app.com/privacy-policy?utm_source=app&utm_content=privacy_link"
          )}>
        <div class="item-media">
          <i class="icon material-icons">privacy_tip</i>
        </div>
        <div class="item-inner">
          <div class="item-title">{tr("privacy.policy")}</div>
        </div>
      </a>
    </li>
    <!-- TODO Sheet mode not working -->
    <ListItem
      title={tr("privacy.data_analytics")}
      smartSelect
      openIn="sheet"
      class="smart-select-analytics"
      bind:this={smartSelectEl}>
      <Icon slot="media" material="dataset"></Icon>
      <select name="analytics">
        <option value="true">{tr("privacy.optin")}</option>
        <option value="false">{tr("privacy.optout")}</option>
      </select>
    </ListItem>
    {#if !analyticsEnabled}
      <li>
        <div class="item-content">
          <div class="" style="color: cyan">
            {tr("privacy.analytics_disabled")}
          </div>
        </div>
      </li>
    {/if}
    {#if isDebugUser()}
      <li>
        <a href={"#"} class="item-link item-content" on:click={downloadData}>
          <div class="item-media">
            <i class="icon material-icons">download</i>
          </div>
          <div class="item-inner">
            <div class="item-title">Download Data</div>
          </div>
        </a>
      </li>
    {/if}
  </List>
</Page>
