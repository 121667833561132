<script>
  import { Navbar, Page } from "framework7-svelte";
  import { WEBSITE_URL } from "../js/media";
</script>

<Page>
  <Navbar title="Lost your way?" backLink={true} backLinkUrl={"/"} />
  <img
    class="image bottom"
    src={WEBSITE_URL + "/404.webp"}
    alt="not found"
    style="" />
</Page>

<style>
  .bottom {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
  }
</style>
