<script lang="ts">
  export let text;
</script>

<div class="ribbon"><h1>{text}</h1></div>

<style>
  h1 {
    position: relative;
    margin: 0 auto 20px;
    padding: 4px 48px;
    text-align: center;
    background-color: #875e46;
  }

  h1::before,
  h1::after {
    content: "";
    width: 60px;
    height: 100%;
    background-color: #724b34;

    /* position ribbon ends behind and slightly lower */
    position: absolute;
    z-index: -1;
    top: 20px;

    /* clip ribbon end shape */
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 25% 50%);

    /* draw and position the folded ribbon bit */
    background-image: linear-gradient(45deg, transparent 50%, #5d3922 50%);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: bottom right;
  }

  h1::before {
    left: -40px;
  }

  h1::after {
    right: -40px;
    transform: scaleX(-1); /* flip horizontally */
  }

  /* everything below is for demo appearances and not important to the concept */

  .ribbon {
    box-sizing: border-box;
    display: grid;
    align-items: center;
    /* font-family: "Merriweather", serif; */
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 1.2rem;
    }
  }
</style>
