<script>
  import { Block, BlockTitle, f7, Navbar, Page } from "framework7-svelte";
  import NotificationComponent from "../../components/notifications.svelte";
  import { dispatch, EVENT } from "../../js/app.ts";
  import { translate as tr } from "../../js/i18n.ts";

  export let f7router;

  // TODO warn the user when notifications are disabled
  function addNotification() {
    dispatch(EVENT.notificationAdd);
  }

  function saveChanges() {
    dispatch(EVENT.notificationSave);
    f7router.back();
  }
</script>

<Page name="settings-notifications">
  <Navbar title={tr("settings.notifications")} backLink="Back" />
  <Block>{@html tr("about.recommendation")}</Block>
  <BlockTitle>{tr("settings.notifications")}</BlockTitle>

  <NotificationComponent />

  <Block>
    <div class="grid grid-cols-2 grid-gap">
      <button
        href={"#"}
        on:click={addNotification}
        id="add-button"
        class="button button-fill color-blue">
        <i class="icon material-icons">add</i>
        {tr("common.add")}
      </button>
      <button
        href={"#"}
        on:click={saveChanges}
        id="save-button"
        class="back button button-fill color-primary">
        <i class="icon material-icons">save</i>
        {tr("common.save")}
      </button>
    </div>
  </Block>
</Page>
