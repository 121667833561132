
import { App } from '@capacitor/app';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import Framework7 from 'framework7/bundle';
import { ICON_SMALL, setupNotifications } from '../js/notifications.ts';
import { EVENT, closeSplashScreen, device, minimizeApp, runAndLogErrors, subscribe } from './app.ts';
import { translate } from './i18n.ts';
import { isFirstStart } from './settings.ts';
import store from './store.ts';



/**
 * Starts/Stops the foreground service that keeps the application running in the background
 */
class BackgroundPlugin {

  private bgPlugin;

  constructor() {
    this.bgPlugin = cordova?.plugins?.backgroundMode;
    if (!this.bgPlugin) {
      console.warn('backgroundmode: plugin not available');
      return;
    }

    // listen for changes
    this.bgPlugin.on('activate', () => {
      console.debug('backgroundmode:activate event');
      this.bgPlugin.disableWebViewOptimizations(); // keep javascript running in the background
    });
    this.bgPlugin.on('deactivate', function () {
      console.debug('backgroundmode:deactivate event');
      // nothing to do here
    });
  }


  /**
   * Starts the foreground service that keeps the application running in the background
   */
  public keepRunningBackground = () => {
    this.bgPlugin?.setDefaults({
      title: 'Coherence',
      text: translate('messages.notification_ongoing_session'),
      icon: ICON_SMALL,
      color: '8A2BE2', // should match iconColor in capacitor.config.ts
      hidden: false, // make it stick and show on lock screen
      silent: false // make it stick and show on lock screen
    });

    // start background mode
    this.bgPlugin?.setEnabled(true);
  }

  /**
 * Stops the foreground service that keeps the application running in the background
 */
  public stopRunningBackground = () => {
    // deactivate background mode
    this.bgPlugin?.setEnabled(false);
  }

}

/**
 * Only imported or run on native code (capacitor app instance)
 */
export class CapacitorApp {

  constructor(f7: Framework7) {
    this.init(f7);
  }

  init = (f7: Framework7) => {
    // Handle Android back button
    this.handleAndroidBackButton(f7);

    // Handle Keyboard
    this.handleKeyboard(f7);

    // extra listeners
    this.handleAppEvents(f7);

    // schedule notifications (and catch errors)
    if (!isFirstStart()) { // on first start the introduction page will do this 
      runAndLogErrors(() => setupNotifications(f7));
    }
    // close splash now
    closeSplashScreen();
  }

  handleAppEvents = async (f7: Framework7) => {
    const bgPlugin = new BackgroundPlugin();

    subscribe(EVENT.sessionStateChange, (inSession: boolean) => {
      console.debug(`${EVENT.sessionStateChange} inSession=${inSession}`);
      // update session state in global store 
      store.dispatch('setInSession', inSession) // TODO is this used?

      // enable background mode
      if (inSession) {
        bgPlugin.keepRunningBackground(); // keep running when put in the background
      } else {
        bgPlugin.stopRunningBackground(); // stop running when put in the background
      }
    });

    await App.addListener('appUrlOpen', (data) => {
      console.debug('App opened with URL: ' + data.url);
    });

    await App.addListener('appRestoredResult', (data) => {
      console.debug('Restored state:', data);
    });
  }

  /*
  This method prevents back button tap to exit from app on android.
  In case there is an opened modal it will close that modal instead.
  In case there is a current view with navigation history, it will go back instead.
  */
  handleAndroidBackButton = async (f7: Framework7) => {
    const $ = f7.$;
    App.addListener('backButton',
      function () {
        if ($('.session-sunrise').length) { // prevent stopping a running session
          // TODO instead check a current view / page has a 'onBackButton()' function and make it generic
          if ($('.fab').length) {
            f7.fab.toggle('.fab');
          }
          return;
        }
        // close dialogs
        if ($('.actions-modal.modal-in').length) {
          f7.actions.close('.actions-modal.modal-in');
          return;
        }
        if ($('.dialog.modal-in').length) {
          f7.dialog.close('.dialog.modal-in');
          return;
        }
        if ($('.sheet-modal.modal-in').length) {
          f7.sheet.close('.sheet-modal.modal-in');
          return;
        }
        if ($('.popover.modal-in').length) {
          f7.popover.close('.popover.modal-in');
          return;
        }
        if ($('.popup.modal-in').length) {
          if ($('.popup.modal-in>.view').length) {
            const currentView = f7.views.get('.popup.modal-in>.view');
            if (currentView && currentView.router && currentView.router.history.length > 1) {
              currentView.router.back();
              return;
            }
          }
          f7.popup.close('.popup.modal-in');
          return;
        }
        // close login screen
        if ($('.login-screen.modal-in').length) {
          f7.loginScreen.close('.login-screen.modal-in');
          return;
        }
        // close search
        if ($('.page-current .searchbar-enabled').length) {
          f7.searchbar.disable('.page-current .searchbar-enabled');
          return;

        }
        // close cards
        if ($('.page-current .card-expandable.card-opened').length) {
          f7.card.close('.page-current .card-expandable.card-opened');
          return;
        }
        // close panels
        if ($('.panel.panel-in').length) {
          f7.panel.close('.panel.panel-in');
          return;
        }

        // go back one page on back button
        const currentView = f7.views.current;
        if (currentView && currentView.router && currentView.router.history.length > 1) {
          currentView.router.back();
          return;
        }

        // leave when hitting back on home screen
        if (currentView === f7.views.main) {
          minimizeApp();
          return;
        }
      }
    );
  }
  /*
  This method does the following:
    - provides cross-platform view "shrinking" on keyboard open/close
    - hides keyboard accessory bar for all inputs except where it required
  */
  handleKeyboard = (f7: Framework7) => {
    const $ = f7.$;
    // IOS only
    if (device.ios) {
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
      Keyboard.setScroll({ isDisabled: true });
      Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
    // end IOs only

    window.addEventListener('keyboardWillShow', () => {
      f7.input.scrollIntoView(document.activeElement as HTMLElement, 0, true, true);
    });
    window.addEventListener('keyboardDidShow', () => {
      f7.input.scrollIntoView(document.activeElement as HTMLElement, 0, true, true);
    });
    window.addEventListener('keyboardDidHide', () => {
      if (document.activeElement && $(document.activeElement).parents('.messagebar').length) {
        return;
      }
      // IOS only
      if (device.ios) {
        Keyboard.setAccessoryBarVisible({ isVisible: true });
      }
    });

    $(document).on(
      'touchstart',
      'input, textarea, select',
      function (e: any) { // eslint-disable-line
        const nodeName = e.target.nodeName.toLowerCase();
        const type = e.target.type;
        const showForTypes = ['datetime-local', 'time', 'date', 'datetime'];

        if (device.ios) {
          if (nodeName === 'select' || showForTypes.indexOf(type) >= 0) {
            Keyboard.setAccessoryBarVisible({ isVisible: true });
          } else {
            Keyboard.setAccessoryBarVisible({ isVisible: false });
          }
        }
      },
      true,
    );
  }
};
