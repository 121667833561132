import { ActionPerformed, LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications';
import Framework7 from 'framework7/bundle';
import { getTranslationValue } from '../js/i18n.ts';
import { APP_NAME, getStoredValue, setStoredValue, StoreValue } from '../js/settings.ts';
import { Event, recordEvent } from './analytics.ts';
import { device, logAndShowError, logError } from './app.ts';
import { ANDROID_REMINDER_SOUND } from './media.ts';
import { ROUTE_HOME } from './routes.ts';
import { PersistentRandomStringGenerator } from './utils.ts';

export const ICON_SMALL = 'ic_notification';
export const ICON_LARGE = 'ic_notification_large';

const ANDROID_NOTIFICATION_CHANNEL_ID = 'com.genux.notification.reminder';

/** notification ID used when a timer is finished*/
const TIMER_NOTIF_ID = 5;


interface NotificationTime {
    hour: number,
    minute: number
}

function hasNotificationPlugin(): boolean {
    return device.native && typeof LocalNotifications !== 'undefined';
}

export function getStoredNotificationTimes(): NotificationTime[] {
    const defaultNotifications = [
        { hour: 8, minute: 0 },
        { hour: 13, minute: 0 },
        { hour: 18, minute: 0 },
    ]

    return getStoredValue(StoreValue.notifications, defaultNotifications);
}

/**
 * Stores the notifications settings and re-schedule notifications
 * 
 * @param notifs a array of notifications to schedule
 */
export function setStoredNotificationTimes(notifs: NotificationTime[], scheduleNow: boolean = true) {
    // sanitize array
    const cleanNotifs: NotificationTime[] = Array.from(notifs, (n) => {
        return { hour: n.hour, minute: n.minute }
    });
    // sort notifications by hour
    cleanNotifs.sort(function (a, b) { return a.hour - b.hour });
    // TODO: sort by hour/time
    setStoredValue(StoreValue.notifications, cleanNotifs);
    // re-schedule notifications
    if (scheduleNow) {
        scheduleNotifications(true); // cancel if there's no notifications saved
    }
}

/**
 * 
 * @returns a unique random cool sentence for notifications
 */
function getNextUniqueMessage(): string {
    const notificationMessages = getTranslationValue('messages.notifications') as [] ?? [];
    const uniqueRandomMessageGenerator = new PersistentRandomStringGenerator('notifications', notificationMessages);
    return uniqueRandomMessageGenerator.getNext();
}

async function scheduleStoredNotifications(notifTimes: NotificationTime[]) {
    const schedules: LocalNotificationSchema[] = [];
    const START_ID = 10;
    // cancel all notifications first
    try {
        await cancelAll()
    } catch (err) {
        logError("notifications:cancelAll caught error", err)
    }
    // create notifications
    for (const ntime of notifTimes) {
        const title = getNextUniqueMessage(); // different sentence every time
        const message = APP_NAME; // could use ntime.hour here for less generic Hey {user}
        // add to the scheduled notifications
        schedules.push({
            id: START_ID + schedules.length,
            title: title, // TODO remove split and do separate trs
            body: message,
            channelId: ANDROID_NOTIFICATION_CHANNEL_ID,
            // smallIcon: ICON_SMALL, set in capacitor.config.js:LocalNotifications
            schedule: {
                on: {
                    hour: ntime.hour,
                    minute: ntime.minute,
                    second: 0
                },
                allowWhileIdle: true
            }
        })
    }
    // schedule
    try {
        if (schedules.length) {
            await LocalNotifications.schedule({
                notifications: schedules
            });
        }
        console.debug(`notifications: scheduled count=${schedules.length}`, schedules)
        return schedules;

    } catch (err) {
        logError("notifications:schedule caught error", err)
    }
}

async function cancelAll() {
    const pendingNotifications = await LocalNotifications.getPending()
    const count = pendingNotifications?.notifications?.length || 0
    if (count > 0) {
        await LocalNotifications.cancel({
            notifications: pendingNotifications.notifications
        })
    }
    console.debug(`notifications: cancelled count=${count}`)
}

export async function scheduleNotifications(scheduleWhenEmpty: boolean = false) {
    if (!hasNotificationPlugin()) return;

    const GRANTED = 'granted'
    const notifTimes = getStoredNotificationTimes();
    if (notifTimes.length === 0 && !scheduleWhenEmpty) {
        console.debug(`notifications: no notifications to be scheduled`);
        return;
    }

    try {
        if (device.android) { // allow to set custom sounds on android
            await LocalNotifications.createChannel({
                id: ANDROID_NOTIFICATION_CHANNEL_ID,
                name: 'Daily Reminders',
                description: 'Daily Reminders',
                sound: ANDROID_REMINDER_SOUND,
            });

            // hack to tweak the channel name of plugin-background
            await LocalNotifications.createChannel({
                id: 'cordova-plugin-background-mode-id',
                name: 'Ongoing Session',
                description: 'Ongoing Session',
            });
        }

        let res = await LocalNotifications.checkPermissions();
        if (res.display === GRANTED) {
            return await scheduleStoredNotifications(notifTimes) // schedule
        } else { // no permissions, request them
            try {
                // ask 3 times
                for (let requestedCount = 0; requestedCount < 3; requestedCount++) {
                    res = await LocalNotifications.requestPermissions();
                    if (res.display === GRANTED) {
                        return await scheduleStoredNotifications(notifTimes) // schedule
                    } else {
                        console.warn(`notifications: user did not grant permissions count=${requestedCount} data=${JSON.stringify(res)}`);
                    }
                }
            }
            catch (err) {
                logError('notifications:requestPermissions caught an error', err)
            }
        }
    }
    catch (err) {
        logError('notifications:checkPermissions caught an error', err)
    }
}

export const hideSessionNotification = async () => {
    if (!hasNotificationPlugin()) {
        console.warn('notifications:hideSessionNotification No notifications plugin available, notifications disabled');
        return;
    }

    await LocalNotifications.removeAllDeliveredNotifications()
    console.debug(`notifications:hideSessionNotification`);
}

export const onNotificationDisplayed = (f7: Framework7, notification: LocalNotificationSchema) => {
    const nowDate = new Date();
    console.debug('notifications:onNotificationDisplayed', notification);
    if (notification.id === TIMER_NOTIF_ID) {
        // could send eevent here
        const scheduledAt = notification.schedule?.at;
        if (scheduledAt) {
            console.debug(`notifications:onNotificationDisplayed time difference=${nowDate.getTime() - scheduledAt.getTime()}ms`);
        }
    }
}

export const onNotificationClick = (f7: Framework7, notificationAction: ActionPerformed) => {
    console.debug('notifications:onNotificationClick', notificationAction);
    // record as pageview for stats
    recordEvent(Event.PageView, { page_location: '/notifications/scheduled' });
    // start coherence session immediately
    const router = f7.views.main?.router; // needs the view's router instance to work
    if (router) {
        router.navigate(ROUTE_HOME, {
            reloadCurrent: true, // force refresh
            ignoreCache: true, // force refresh
            clearPreviousHistory: true, // clear history
            history: false, // don't add this route to history
            browserHistory: false, // don't add this route to history
            props: {
                notui: true, // indicate user did not interact with screen (no click event)
            },
        });
    } else {
        logAndShowError('Local notification clicked but no view/router available');
    }
}

/**
 * Setup notifications for application
 * 
 * @param f7 the Framework7 app instance
 */
export const setupNotifications = async (f7: Framework7) => {
    if (!hasNotificationPlugin()) {
        console.warn('notifications: No notifications plugin available, notifications are disabled');
        return;
    }

    // click notification listener
    await LocalNotifications.addListener('localNotificationActionPerformed', (notificationAction) => onNotificationClick(f7, notificationAction));
    await LocalNotifications.addListener('localNotificationReceived', (notification) => onNotificationDisplayed(f7, notification));

    // check permissions and schedule notifications
    await scheduleNotifications(false); // not scheduling (cancelling) empty notifications
};

