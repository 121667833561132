<script lang="ts">
  import { Badge } from "framework7-svelte";

  export let checkedDays: Map<string, boolean>;

  if (checkedDays.size != 7) {
    console.warn(`invalid week array ${checkedDays}`);
  }
</script>

<div class="badges">
  {#each [...checkedDays] as [dayName, practiced]}
    <Badge
      color={practiced ? "green" : "black"}
      textColor={practiced === true ? "black" : "white"}>
      {dayName.slice(0, 1).toLocaleUpperCase()}
    </Badge>
  {/each}
</div>

<style>
  :global(.badges > span) {
    font-size: 1em;
    margin-left: 6px;
    height: 2em;
    width: 2em;
  }
</style>
