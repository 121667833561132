<script>
  import SettingsComponent from "../../components/session-settings.svelte";
  import tr from "../../js/i18n.ts";
</script>

<div class="page" data-name="settings-session">
  <div class="navbar">
    <div class="navbar-inner sliding">
      <div class="left">
        <a href={"#"} class="link back">
          <i class="icon icon-back"></i>
          <span class="if-not-md">Back</span>
        </a>
      </div>
      <div class="title">{tr("settings.session_media")}</div>
    </div>
  </div>
  <div class="page-content">
    <div class="block" innerHTML={tr("settings.session_media_description")}>
    </div>
    <SettingsComponent showBreathTime={true} />
  </div>
</div>
