import { createStore } from 'framework7/bundle';

interface Product {
  id: number,
  title: string,
  description: string
}

interface State {
  isInSession: boolean,
  products: Product[],
}

const store = createStore({
  state: {
    isInSession: false,
    products: [
      {
        id: '1',
        title: 'Is Coherence the same as meditation?',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi tempora similique reiciendis, error nesciunt vero, blanditiis pariatur dolor, minima sed sapiente rerum, dolorem corrupti hic modi praesentium unde saepe perspiciatis.'
      },
      {
        id: '2',
        title: 'Does Coherence work?',
        description: 'Velit odit autem modi saepe ratione totam minus, aperiam, labore quia provident temporibus quasi est ut aliquid blanditiis beatae suscipit odio vel! Nostrum porro sunt sint eveniet maiores, dolorem itaque!'
      },
      {
        id: '3',
        title: 'How long before I see results?',
        description: 'Expedita sequi perferendis quod illum pariatur aliquam, alias laboriosam! Vero blanditiis placeat, mollitia necessitatibus reprehenderit. Labore dolores amet quos, accusamus earum asperiores officiis assumenda optio architecto quia neque, quae eum.'
      }
    ]
  },
  getters: {
    isInSession({ state }: { state: State }) {
      return state.isInSession;
    },
    products({ state }: { state: State }) {
      return state.products;
    }
  },
  actions: {
    setInSession({ state }: { state: State }, inSession: boolean) {
      state.isInSession = inSession;
    },
    addProduct({ state }: { state: State }, product: Product) {
      state.products = [...state.products, product];
    },
  },
})

export default store;
