<script lang="ts">
  import { f7, f7ready } from "framework7-svelte";
  import { onDestroy, onMount } from "svelte";
  import tr from "../js/i18n.ts";
  import { APP_NAME, getUser } from "../js/settings.ts";

  export let intro: boolean | string = false;

  const user = getUser();
  const noMsg45s = ["", "", "", "", "", "", "", ""];
  const noMsg30s = ["", "", "", "", ""];
  // const noMsg1min = ["", "", "", "", "", "", "", "", "", "", ""];

  const firstTimeMessages = [
    tr("messages.session_1_message_1", user),
    tr("messages.session_1_message_2"),
    tr("messages.session_1_message_3"),
    tr("messages.session_1_message_4"),
    tr("messages.session_1_message_5"),
    tr("messages.session_1_message_6"),
    tr("messages.session_1_message_7"),
    tr("messages.session_1_message_8"),
    tr("messages.session_1_message_9"),
    ...noMsg30s,
    tr("messages.session_1_message_10"),
    ...noMsg30s,
    tr("messages.session_1_message_11"),
    tr("messages.session_1_message_12"),
    tr("messages.session_1_message_13"),
    ""
  ];

  const standardMessages = [
    tr("messages.session_2_message_1"),
    ...noMsg45s,
    tr("messages.session_2_message_2"),
    ...noMsg45s,
    tr("messages.session_2_message_3"),
    ...noMsg45s,
    tr("messages.session_2_message_4"),
    ...noMsg45s,
    tr("messages.session_2_message_5"),
    ""
  ];

  const sleepMessages = [
    tr("messages.session_sleep_message_1", { name: APP_NAME }),
    tr("messages.session_sleep_message_2"),
    tr("messages.session_sleep_message_3"),
    tr("messages.session_sleep_message_4"),
    tr("messages.session_sleep_message_5"),
    tr("messages.session_sleep_message_5"),
    tr("messages.session_sleep_message_5"),
    ""
  ];

  const texts =
    intro === "sleep"
      ? sleepMessages
      : intro === true
        ? firstTimeMessages
        : standardMessages;

  let textIndex = 0;
  let currentTimerId: null | number = null;
  let messageEl: HTMLElement;

  function showNextSentence() {
    const message = texts[textIndex % texts.length];
    const msgEl = f7.$(messageEl);

    if (textIndex === 0) {
      // no need to hide the first displayed text
      msgEl.text(message);
      msgEl.animate(
        { opacity: 1 },
        {
          // show new message
          duration: 1000,
          easing: "swing"
        }
      );
    } else {
      msgEl
        .animate(
          { opacity: 0 },
          {
            // hide previous
            duration: 500,
            easing: "swing",
            complete: function () {
              msgEl.text(message); // show the message
            }
          }
        )
        .animate(
          { opacity: 1 },
          {
            // show new message
            duration: 1000,
            easing: "swing"
          }
        );
    }

    // schedule next message if there's more
    textIndex = textIndex + 1;
    if (textIndex < texts.length) {
      currentTimerId = window.setTimeout(showNextSentence, 5000); // 4.5 secs because 500ms to hide previous message
    }
  }

  onMount(() => {
    f7ready(showNextSentence);
  });

  onDestroy(() => {
    if (currentTimerId !== null) {
      clearTimeout(currentTimerId); // stop timers
    }
  });
</script>

<div id="messages">
  <div id="container">
    <span id="message-text" bind:this={messageEl}></span>
  </div>
</div>

<style>
  /* @import url("https://fonts.googleapis.com/css2?family=Merienda:wght@700&display=swap"); */

  #container {
    position: absolute;
    margin: auto;
    width: 100vw;
    height: 30pt;
    top: 54%;
    margin-top: 4pt;
    bottom: 0;
    filter: url(#threshold) blur(0.6px);
    z-index: 42;
  }

  #message-text {
    position: absolute;
    opacity: 0;
    /* width: 100%; */
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    /* font-family: 'Merienda', cursive; */
    font-weight: bolder;
    font-size: 26pt;
    text-shadow: 2px 2px 2px #8e80a8;
    text-align: center;
    z-index: 43;
  }
</style>
