<script>
  import { f7, f7ready } from "framework7-svelte";
  import { onDestroy, onMount } from "svelte";
  import { EVENT, subscribe, unsubscribe } from "../js/app.ts";
  import tr from "../js/i18n.ts";
  import {
    getStoredNotificationTimes,
    setStoredNotificationTimes
  } from "../js/notifications.ts";
  import TimePicker from "./timepicker.svelte";

  export let scheduleOnSave = true;
  let el;

  let notifications = updateNotifications(getStoredNotificationTimes());

  function updateNotifications(notifs) {
    return notifs.map((n, idx) => {
      return {
        id: `${idx}`,
        enabled: true,
        name: getNotificationName(n.hour),
        hour: n.hour,
        minute: n.minute
      };
    });
  }

  // TODO warn the user when notifications are disabled
  function addNotification() {
    notifications = updateNotifications([
      ...notifications,
      { hour: 22, minute: 0 }
    ]);
  }

  function onTimeChange(hour, minute, notifId) {
    const notif = notifications[notifId];
    notif.hour = hour;
    notif.minute = minute;
    notifications = updateNotifications(notifications);
  }

  function getNotificationName(hourOfDay) {
    if (hourOfDay >= 12 && hourOfDay < 18) {
      return tr("common.afternoon");
    } else if (hourOfDay <= 3 || hourOfDay >= 18) {
      return tr("common.evening");
    }
    return tr("common.morning");
  }

  function parseHourMin(hourString) {
    let [hour, minute] = hourString.split(":");
    if (hour) hour = Number(hour);
    if (minute) minute = Number(minute);
    return [hour, minute];
  }

  function saveChanges() {
    // TODO safety checks
    let savingNotifs = []; // fresh notifications array

    // only "checked items" will return a value here
    // TODO there should be a better way to do this without getting the string value
    const formData = f7.form.convertToData(el);
    let count = 0;
    Object.values(formData).forEach(function (value) {
      // check there is a value (item checked)
      if (value && value.length) {
        const notif = notifications[count]; // use the value in our internal state
        savingNotifs.push({ hour: notif.hour, minute: notif.minute });
      }
      count++;
    });

    // update device notifications
    setStoredNotificationTimes(savingNotifs, scheduleOnSave);

    // refresh display
    notifications = updateNotifications(getStoredNotificationTimes());
  }

  // clear event listeners
  onDestroy(() => {
    unsubscribe(EVENT.notificationSave, saveChanges);
    unsubscribe(EVENT.notificationAdd, addNotification);
  });

  // listen to add events
  onMount(() => {
    // TODO use svelte events instead of global events here instead
    f7ready(() => {
      subscribe(EVENT.notificationSave, saveChanges);
      subscribe(EVENT.notificationAdd, addNotification);
    });
  });
</script>

<form
  class="list list-outline-ios list-strong-ios list-dividers-ios"
  id="form-notification"
  bind:this={el}>
  <ul>
    {#each notifications as notif (notif.id)}
      <li>
        <label class="item-checkbox item-content">
          <input
            type="checkbox"
            name={notif.id}
            id={notif.id}
            value="{notif.hour}:{notif.minute}"
            checked={notif.enabled ? "checked" : ""} />
          <i class="icon icon-checkbox"></i>
          <div class="item-inner">
            <div class="item-title">{notif.name}</div>
            <div class="item-after">
              <TimePicker
                changed={(hr, min) => onTimeChange(hr, min, notif.id)}
                hour={notif.hour}
                minute={notif.minute} />
            </div>
          </div>
        </label>
      </li>
    {/each}
  </ul>
</form>

<style>
  :global(.calendar-time-selector) {
    padding: unset;
  }
</style>
