import { get } from "svelte/store";

import { persisted } from 'svelte-persisted-store';

export const APP_NAME = 'Coherence';


const PREFIX = 'coherence-';
export enum StoreValue { // do not rename options here or stored values will be lost
  start_count = PREFIX + 'start-count',
  session_stats = PREFIX + 'session-stats',
  settings = PREFIX + 'settings',
  notifications = PREFIX + 'notifications',
  userinfo = PREFIX + 'userinfo',
  favorite_time = PREFIX + 'favorite_time',
  /** analtyics disabled when present */
  analytics = PREFIX + 'analytics',
}

/**
 * Represents the user
 */
export interface User {
  /** 
  * User name, can be empty string
  */
  name: string;
}

/**
 * Stored settings
 */
export interface Settings {
  version: number;
  sound_effects_enabled: boolean;
  vibrations_enabled: boolean;
  background_music_enabled: boolean;
  messages_enabled: boolean;
  breathing_time: number;
  default_session_time: number;
}

export enum SETTINGS {
  sound_effects_enabled = 'sound_effects_enabled',
  vibrations_enabled = 'vibrations_enabled',
  background_music_enabled = 'background_music_enabled',
  messages_enabled = 'messages_enabled',
  breathing_time = 'breathing_time',
  default_session_time = 'default_session_time',
}


export const DEFAULT_SETTINGS: Settings = Object.freeze({ // default settings
  version: 1,
  sound_effects_enabled: false,
  vibrations_enabled: true,
  background_music_enabled: true,
  messages_enabled: true,
  breathing_time: 5,
  default_session_time: 5
});

export const isSettingEnabled = (setting: SETTINGS): boolean => {
  const value = getSetting(setting);
  if (value) { // non-empty string, true, 42, Infinity, [], ...
    return true;
  }
  return false;
}

export const setSettings = (settings: Settings): void => {
  if (!settings) throw Error('missing setting parameter');
  const prevSettings = getSettings();
  // make sure merge the previous settings
  const newSettings = { ...prevSettings, ...settings };
  setStoredValue(StoreValue.settings, newSettings);
  console.debug('settings: Updated settings', newSettings);
}

export const setSetting = (setting: SETTINGS, value: object | number | string | boolean | Array<any>): void => { // eslint-disable-line  @typescript-eslint/no-explicit-any
  if (!setting) throw Error('missing setting parameter');
  const settings = getStoredValue(StoreValue.settings, { ...DEFAULT_SETTINGS }); // returns a copy of default settings
  // update the settings
  settings[setting] = value;
  // save
  setStoredValue(StoreValue.settings, settings);
  // updated setting
  console.debug(`settings:setSetting(${setting}): ${value}`);
}

export const getSettings = (): Settings => {
  return getStoredValue(StoreValue.settings, { ...DEFAULT_SETTINGS }); // returns a copy of default settings if not found
}

export const getSetting = (setting: SETTINGS): null | object | number => {
  if (!setting) throw Error('settings: missing setting parameter');
  const settings = getStoredValue(StoreValue.settings, { ...DEFAULT_SETTINGS }); // returns a copy of default settings
  return settings && setting in settings ? settings[setting] : undefined;
}


/**
 * Deletes a value from localStorage
 */
export const deleteStoredValue = (key: StoreValue): void => {
  window.localStorage.removeItem(key);
}

/**
 * Set a value to be stored in localStorage
 */
const _setStoredValue = (key: string, value: object): void => {
  // use svelte localStorage based persisted store
  persisted(key, {}).set(value); // uses JSON.stringify under the hood
}

/**
 * Get a value stored in localStorage
 */
const _getStoredValue = (key: string): object | null => {
  // use svelte localStorage based persisted store
  return get(persisted(key, null));
}

/**
 * Stores a value in local storage
 */
export const setStoredValue = (key: StoreValue, value: object): void => {
  _setStoredValue(key, value);
}

/**
 * Get a value from local storage
 */
export const getStoredValue = (key: StoreValue, defaultValue: any = null): any => { // eslint-disable-line  @typescript-eslint/no-explicit-any
  const value = _getStoredValue(key);
  return value ?? defaultValue;
}

/**
 * Stores a value in local storage
 */
export const setRawStoredValue = (key: string, value: object): void => {
  _setStoredValue(PREFIX + key, value);
}

/**
 * Get a value from local storage
 */
export const getRawStoredValue = (key: string, defaultValue: any = null): any => { // eslint-disable-line  @typescript-eslint/no-explicit-any
  const value = _getStoredValue(PREFIX + key);
  return value ?? defaultValue;
}

/**
 * 
 * @returns A JSON dump of the current local store
 */
export const localStorageExport = () => {
  return JSON.stringify(localStorage, null, 2);
}

/**
 * A JSON import to the current local store
 * @param data the data to import ot hte local store
 */
export const localStorageImport = (data: any) => { // eslint-disable-line  @typescript-eslint/no-explicit-any
  Object.keys(data).forEach(function (key) { localStorage.setItem(key, data[key]) })
}


/** Matching the old form #live-settings storage  */
export const SETTING_KEY_V0 = 'f7form-live-settings';

/**
 * Detect previous settings and update them if needed
 */
export const setupSettings = (): boolean => {
  if (_getStoredValue(StoreValue.settings) !== null) {
    return false; // we have settings not a first start
  }
  console.log(`Welcome to ${APP_NAME} ! (first start)`) // TODO move in app init
  // first start, setup default settings
  setSettings(DEFAULT_SETTINGS);
  return true; // first start
}

// settings initialization, migrate old settings if needed
const firstStart = setupSettings();

/**
 * @returns true if it's the very first start of the application
 */
export const isFirstStart = (): boolean => {
  return firstStart;
}

export enum SessionType {
  coherence = 1, // cardiac coherence
  meditation = 2, // timer
};

/**
 * Represents the date, length of a Coherence session
 */
export interface SessionStats {
  /** date as number @see Date.getTime() */
  date: number,
  /** how long in seconds */
  elapsed: number,
  /** type of session */
  type: SessionType,
}

/**
 * Adds a session to the stored statistics
 */
export const addSessionStats = (stat: SessionStats) => {
  const sessionStats = getSessionStats();
  const cleanStat = { date: stat.date, elapsed: Math.round(stat.elapsed), type: stat.type }; // data cleanup
  sessionStats.push(cleanStat)
  setStoredValue(StoreValue.session_stats, sessionStats);
  console.debug('settings:addSessionStats', { ...cleanStat, date: new Date(cleanStat.date) }); // better date display
}

/**
 * Get the history of Coherence sessions
 */
export const getSessionStats = (): SessionStats[] => {
  return getStoredValue(StoreValue.session_stats, []);
}

/**
 * First time virgin or was there any previous stored sessions
 */
export const isFirstCoherenceSession = () => {
  return getSessionStats().length == 0;
}

/**
 * Get the saved user or an empty named one
 */
export const getUser = (): User => {
  return getStoredValue(StoreValue.userinfo, { name: '' });
}

/**
 * Store user information
 */
export const setUser = (user: User): void => {
  return setStoredValue(StoreValue.userinfo, user);
}

/**
 * Check is the user name is debug
 */
export const isDebugUser = (): boolean => {
  return getUser().name?.toLowerCase() === 'debug';
}
