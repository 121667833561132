<script>
  import { f7 } from "framework7-svelte";
  import tr, { getLanguage } from "../js/i18n.ts";

  export let hour;
  export let minute;
  export let changed; // client function
  let el;

  if (hour < 0 || minute < 0) {
    throw new Error(`Invalid time value hour=${hour} minute=${minute}`);
  }
  // shown in the DOM
  let currentDisplayValue;

  // format time in the current locale
  const locale = getLanguage();
  const timeFormatter = new Intl.DateTimeFormat(locale, {
    hour: "numeric",
    minute: "numeric"
  });

  // lame fake calendar Object
  const calendar = {
    setValue: (hours, minutes) => {
      setCurrentHourMinute(hours, minutes);
      if (changed) {
        // call listeners : TODO more checks needed
        changed(hours, minutes);
      }
    }
  };

  const timeFormatCheckDate = timeFormatter.format(new Date()).toLowerCase();
  calendar.is12HoursFormat =
    timeFormatCheckDate.indexOf("pm") >= 0 ||
    timeFormatCheckDate.indexOf("am") >= 0;

  function setCurrentHourMinute(hours, minutes) {
    calendar.value = [hours, minutes];
    let dt = new Date();
    dt.setHours(hours, minutes);
    currentDisplayValue = timeFormatter.format(dt.setHours(hours, minutes));
  }
  // initialize the date with hours and minutes
  setCurrentHourMinute(hour, minute);

  function openPicker() {
    openTimePicker(el, f7);
  }

  function closeTimePicker() {
    const { is12HoursFormat } = calendar; // TODO fix
    if (calendar.timePickerInstance) {
      const timePickerValue = calendar.timePickerInstance.value;
      let hours = parseInt(timePickerValue[0], 10);
      const minutes = parseInt(timePickerValue[1], 10);
      const period = calendar.timePickerInstance.value[2];
      if (is12HoursFormat) {
        if (period === "AM" && hours === 12) {
          hours = 0;
        } else if (period === "PM" && hours !== 12) {
          hours += 12;
        }
      }
      // update value
      calendar.setValue(hours, minutes);

      if (calendar.timePickerPopover && calendar.timePickerPopover.opened)
        calendar.timePickerPopover.close();
    }
  }

  function openTimePicker(el, app) {
    const { is12HoursFormat } = calendar;
    f7.$(el).append(
      '<div class="popover calendar-popover calendar-time-picker-popover">' +
        '<div class="popover-inner"><div class="calendar-time-picker"></div></div></div>'
    );
    const hoursArr = [];
    const minutesArr = [];
    const hoursMin = is12HoursFormat ? 1 : 0;
    const hoursMax = is12HoursFormat ? 12 : 23;
    for (let i = hoursMin; i <= hoursMax; i += 1) {
      hoursArr.push(i);
    }
    for (let i = 0; i <= 59; i += 1) {
      minutesArr.push(i);
    }
    let value;
    if (calendar.value && calendar.value.length) {
      value = [calendar.value[0], calendar.value[1]];
    } else {
      value = [new Date().getHours(), new Date().getMinutes()];
    }
    if (is12HoursFormat) {
      value.push(value[0] < 12 ? "AM" : "PM");
      if (value[0] > 12) value[0] -= 12;
      if (value[0] === 0) value[0] = 12;
    }
    calendar.timePickerPopover = app.popover.create({
      el: f7.$(el).find(".calendar-time-picker-popover"),
      targetEl: f7.$(el).find(".calendar-time-selector .link"),
      backdrop: true,
      backdropUnique: true,
      on: {
        close() {
          closeTimePicker();
        },
        closed() {
          if (calendar.timePickerPopover.$el)
            calendar.timePickerPopover.$el.remove();
          calendar.timePickerPopover.destroy();
          if (calendar.timePickerInstance) {
            calendar.timePickerInstance.close();
            calendar.timePickerInstance.destroy();
          }
          delete calendar.timePickerInstance;
          delete calendar.timePickerPopover;
        }
      }
    });
    calendar.timePickerPopover.open();
    calendar.timePickerInstance = app.picker.create({
      containerEl: calendar.timePickerPopover.$el.find(".calendar-time-picker"),
      value,
      toolbar: true,
      rotateEffect: false,
      toolbarCloseText: tr('common.save'),
      cols: [
        {
          values: hoursArr
        },
        {
          divider: true,
          content: ":"
        },
        {
          values: minutesArr,
          displayValues: minutesArr.map((m) => (m < 10 ? `0${m}` : m))
        },
        ...(is12HoursFormat
          ? [
              {
                values: ["AM", "PM"]
              }
            ]
          : [])
      ]
    });
  }
</script>

<div class="calendar-time-selector" bind:this={el}>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-missing-attribute -->
  <a
    class="link"
    data-hour={calendar.value[0]}
    data-minute={calendar.value[1]}
    on:click={openPicker}
    >{currentDisplayValue}<i
      class="icon material-icons"
      style="font-size: 20px;margin-left: 5px;">edit</i
    ></a>
</div>
