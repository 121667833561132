<script lang="ts">
  import { App, f7, f7ready, View } from "framework7-svelte";
  import { onMount } from "svelte";
  import { registerSW } from "virtual:pwa-register";
  import { device } from "./js/app.ts";
  import { CapacitorApp } from "./js/capacitor-app.ts";
  import { f7params } from "./js/appconfig.ts";

  onMount(() => {
    f7ready(() => {
      if (!device.native) {
        // register service worker now only for web browsers
        registerSW({ immediate: true }); // force reload if new update found
      }
      // init
      if (device.native) {
        console.time("capacitorInit");
        new CapacitorApp(f7); // init phone app
        console.timeEnd("capacitorInit");
      }

      console.timeEnd("appStart");
    });
  });
</script>

<App {...f7params}>
  <View main={true} />
</App>
