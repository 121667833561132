<script lang="ts">
  import { Block, Button, Navbar, Page } from "framework7-svelte";
  import { onDestroy, onMount } from "svelte";

  import {
    type AppInfo,
    device,
    getAppInfo,
    isDevMode,
    openEmail
  } from "../js/app.ts";
  import { DriftCorrectingTimer, EMAIL_ADDRESS } from "../js/media.ts";
  import {
    addSessionStats,
    isDebugUser,
    localStorageExport,
    SessionType,
    setStoredValue,
    StoreValue
  } from "../js/settings.ts";

  let appInfo: AppInfo;
  let consoleHistory = console.history;

  // resolve promise ans set variable
  getAppInfo().then((data) => (appInfo = data));

  const testDebug = () => {
    const asyncTest = async () => {
      throw Error("Async test error");
    };

    setTimeout(() => {
      asyncTest();
    }, 3000);

    throw Error("test error");
  };

  const dl_as_file_Blob = (filename_to_dl: string, data_to_dl: string) => {
    let blobx = new Blob([data_to_dl], { type: "text/plain" }); // ! Blob
    let elemx = window.document.createElement("a");
    elemx.href = window.URL.createObjectURL(blobx); // ! createObjectURL
    elemx.download = filename_to_dl;
    elemx.style.display = "none";
    document.body.appendChild(elemx);
    elemx.click();
    document.body.removeChild(elemx);
  };

  const exportData = () => {
    const jsonString = localStorageExport();
    console.log(jsonString);

    // const today = new Date();
    // dl_as_file_Blob(`coherence-data-${today.toISOString()}.json`, jsonString);
  };

  const resetTestData = () => {
    // create a month of data
    const currentDay = new Date();
    const DAYS = 30;
    const types = Object.values(SessionType) as SessionType[];
    // delete stats
    setStoredValue(StoreValue.session_stats, []);
    // create 30 days stats
    for (let index = 0; index < DAYS; index++) {
      currentDay.setDate(currentDay.getDate() - 1);
      // store stats
      addSessionStats({
        date: currentDay.getTime(),
        elapsed: 5 * 60, // 5 min
        type: (index % (types.length - 2)) + 1 // change types
      });
    }
  };

  const refreshTimer = new DriftCorrectingTimer(
    () => (consoleHistory = console.history),
    1000
  );

  onMount(() => refreshTimer.start());
  onDestroy(() => refreshTimer.stop());
</script>

<Page name="about">
  <Navbar title="Debug Information" backLink="Back" />
  <Block>
    <div class="grid grid-cols-2 grid-gap">
      <Button fill color="green" raised onClick={testDebug}>Test</Button>
      <Button fill color="blue" raised onClick={resetTestData}
        >Reset Data</Button>
      <Button fill raised onClick={exportData}>Export data</Button>
      <Button
        fill
        raised
        onClick={() =>
          openEmail(EMAIL_ADDRESS, "Found issue", `${consoleHistory[0].stack}`)}
        class="external link">Report Issue</Button>
    </div>
  </Block>
  <div class="block-title">Device & App Info</div>
  <pre>{device &&
      JSON.stringify(
        {
          ...device,
          ...appInfo,
          isDebugUser: isDebugUser(),
          isDevMode: isDevMode
        },
        null,
        2
      )}
    </pre>
  <table>
    <thead>
      <tr>
        <th class="label-cell">Date</th>
        <th class="label-cell">Lvl</th>
        <th class="label-cell">Log</th>
      </tr>
    </thead>
    <tbody>
      {#each consoleHistory as log}
        <tr>
          <td class="label-cell"
            >{new Date(log.timestamp).toLocaleTimeString("fr-FR")}</td>
          <td class="label-cell">{log.type.slice(0, 1)}</td>
          <td class="label-cell"> {log.arguments[0]}</td>
        </tr>
      {/each}
    </tbody>
    <div>
      <div></div>
    </div>
  </table>
</Page>
