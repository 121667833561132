console.time('appStart'); // start an early timer

// Import Framework7
import Framework7 from "framework7/lite/bundle";

// Import Framework7-Svelte Plugin
import Framework7Svelte from "framework7-svelte";

// Import F7 Styles
import 'framework7/css/bundle';

// Import Icons and App Custom Styles
import '../css/app.css';
import '../css/icons.css';

// not using console history
import './console-history.js';

// Import App Component
import App from "../App.svelte";

// Init F7 Svelte Plugin
Framework7.use(Framework7Svelte);

// Mount Svelte App
export const svelteApp = new App({
  target: document.getElementById("app") as HTMLElement,
});
