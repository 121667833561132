<script lang="ts">
  import { Button } from "framework7-svelte";

  export let icon: string | undefined = undefined;
  export let iconColor: string | undefined = undefined;
  export let text: string | undefined = undefined;
</script>

<Button
  fill
  raised
  class="button-home"
  iconMaterial={icon}
  iconColor={iconColor}
  textColor="white"
  on:click>
  {#if text}
    <span class="button-text">{text}</span>
  {/if}
</Button>


<style>
  /* nlarger icon */
  :global(.button-home > i.icon) {
    font-size: 3em;
  }

  :global(.button-home) {
    padding-left: 2px;
    padding-right: 2px;
    background-color: rgba(46, 8, 73, 0.5);
    height: 6em;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
  }
</style>
