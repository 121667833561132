<script>
  import { Block, f7, Icon, Navbar, Page } from "framework7-svelte";
  import { onDestroy, onMount } from "svelte";
  import { formatDuration } from "../../js/date.ts";
  import { getLanguage, translate as tr } from "../../js/i18n.ts";
  import { getSessionsForDay, getStats } from "../../js/session.ts";
  import { SessionType } from "../../js/settings.ts";

  export let f7router;

  const stats = getStats();
  const language = getLanguage();
  let curDaySessionsRef = []; // selected day sessions list
  let curDayStr = "";

  const onBack = () => {
    // if there's no back page, go home
    if (!f7router) return;
    // use the home page if there's no back page
    if (f7router.history && f7router.history.length < 2) {
      // couting this page
      f7router.navigate("/", {
        animate: true,
        clearPreviousHistory: true
      });
    } else {
      f7router.back();
    }
  };

  stats.durationStr = formatDuration(stats.totalMins * 60 * 1000);

  // helpers data for axis
  const dates = [];
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  for (let i = 0; i < 12; i += 1) {
    dates.push(new Date(year, month - (3 - i)));
  }
  const axisDateFormat = Intl.DateTimeFormat(undefined, {
    month: "short",
    year: "numeric"
  });
  const tooltipDateFormat = Intl.DateTimeFormat(undefined, {
    month: "long",
    year: "numeric"
  });

  let calendarInline;
  let areaChart;

  // create a set of unique days
  const allSessionDates = [];
  const daySet = new Set();

  stats.sessions.forEach((stat) => {
    if (!stat.date) return;
    const dt = new Date(stat.date);
    const dayStr = `${dt.getYear()}-${dt.getMonth()}-${dt.getDate()}`;
    if (daySet.has(dayStr)) return;
    allSessionDates.push(dt);
    daySet.add(dayStr);
  });

  const updateDaysSessions = (year, month, day) => {
    const daySessStats = getSessionsForDay(stats, year, month, day);
    // update display
    curDaySessionsRef = daySessStats.length > 0 ? daySessStats : [];
    curDayStr = new Date(year, month, day).toLocaleDateString(language);
  };

  onMount(() => {
    // Inline with custom toolbar
    var monthNames = [...Array(12).keys()].map((key) =>
      new Date(0, key).toLocaleString(language, { month: "long" })
    );
    // Inline with custom toolbar
    calendarInline = f7.calendar.create({
      containerEl: "#calendar-inline-container",
      multiple: true,
      value: allSessionDates,
      disabled: function (date) {
        const daySessStats = getSessionsForDay(
          stats,
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
        return daySessStats.length === 0; // has a session
      },
      renderToolbar: function () {
        return (
          '<div class="toolbar calendar-custom-toolbar no-shadow" style="background-color: unset;">' +
          '<div class="toolbar-inner">' +
          '<div class="left">' +
          '<a class="link icon-only"><i class="icon icon-back"></i></a>' +
          "</div>" +
          '<div class="center"></div>' +
          '<div class="right">' +
          '<a class="link icon-only"><i class="icon icon-forward"></i></a>' +
          "</div>" +
          "</div>" +
          "</div>"
        );
      },
      on: {
        dayClick: function (calendar, dayEl, year, month, day) {
          updateDaysSessions(year, month, day);
          // workaround de-selection: refresh with all dates to not show de-selection
          // TODO: could be slow with lots of values
          calendarInline.setValue(allSessionDates);
        },
        init: function (c) {
          f7.$(".calendar-custom-toolbar .center").text(
            monthNames[c.currentMonth] + ", " + c.currentYear
          );
          f7.$(".calendar-custom-toolbar .left .link").on("click", function () {
            calendarInline.prevMonth();
          });
          f7.$(".calendar-custom-toolbar .right .link").on(
            "click",
            function () {
              calendarInline.nextMonth();
            }
          );
          // update table display
          const now = new Date();
          updateDaysSessions(now.getFullYear(), now.getMonth(), now.getDate());
        },
        monthYearChangeStart: function (c) {
          f7.$(".calendar-custom-toolbar .center").text(
            monthNames[c.currentMonth] + ", " + c.currentYear
          );
        }
        // change: function (calendar, value) {
        // },
      }
    });

    areaChart = f7.areaChart.create({
      el: ".area-chart-with-legend",
      tooltip: true,
      axis: true,
      axisLabels: dates,
      legend: true,
      toggleDatasets: true,
      formatAxisLabel(date) {
        return axisDateFormat.format(date);
      },
      formatTooltipAxisLabel(date) {
        return tooltipDateFormat.format(date);
      },
      datasets: [
        {
          label: "Minutes",
          color: "#f00",
          values: [100, 300, 127, 47]
        },
        {
          label: "Hours",
          color: "#00f",
          values: [100, 75, 133, 237]
        },
        {
          label: "Breaths",
          color: "#ff0",
          values: [0, 100, 250, 307]
        }
      ]
    });
  });

  onDestroy(() => {
    calendarInline.destroy();
    areaChart.destroy();
  });
</script>

<Page name="stats" class="page-stats" hideBarsOnScroll>
  <Navbar
    title="Coherence {tr('settings.statistics')}"
    backLink={true}
    on:clickBack={onBack} />

  <div id="stats" class="grid-stats">
    <!-- <div class="block-title">Coherence ${tr("common.session", {count: 2})}</div> -->
    <Block>
      <div class="grid grid-cols-1">
        <div>⏲️ {tr("stats.total_time")}: <b>{stats.durationStr}</b></div>
        <div>
          📅 {tr("stats.days_in_row")}:
          <b
            >{stats.currentStreak} ({tr("stats.best")}:
            {stats.bestStreak})</b>
        </div>
        <div>
          💨 {tr("stats.total_breaths")}: <b>{stats.totalBreaths}</b>
        </div>
      </div>
      <div id="calendar-inline-container"></div>
      <div style="margin-left: 8px; font-size: 0.9em">
        🟣 {tr("stats.day_with_coherence")}
      </div>
      <!-- show table only when a day is selected -->
      {#if curDaySessionsRef.length > 0}
        <div class="data-table data-table-init">
          <table>
            <thead>
              <tr>
                <th class="label-cell"
                  >{curDayStr}: {curDaySessionsRef.length}
                  {tr("common.session", {
                    count: curDaySessionsRef.length
                  })}
                </th>
                <th class="numeric-cell"
                  >{tr("common.minute", { count: 2 })}
                </th>
                <th class="label-cell">{tr("common.style")}</th>
              </tr>
            </thead>
            <tbody>
              {#each curDaySessionsRef as stat}
                <tr>
                  <td class="label-cell">
                    {stat.date
                      ? new Date(stat.date).toLocaleTimeString(language)
                      : "Unknown"}
                  </td>
                  <td class="numeric-cell">
                    {stat.elapsed
                      ? (stat.elapsed / 60).toLocaleString(language, {
                          maximumFractionDigits: 1
                        })
                      : "0"}
                  </td>
                  <td class="label-cell">
                    <Icon
                      material={stat.type === SessionType.meditation
                        ? "self_improvement"
                        : "wb_twilight"}></Icon>
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      {/if}
    </Block>
  </div>

  <!-- <div class="toolbar toolbar-bottom tabbar">
      <div class="toolbar-inner">
        <a href="#stats" class="tab-link tab-link-active"><i class="icon material-icons">bar_chart</i>Stats</a>
        <a href="#charts" class="tab-link"><i class="icon material-icons">pie_chart</i>Charts</a>
      </div>
    </div> -->
  <!-- <div class="tabs-animated-wrap">
    <div class="tabs"> -->

  <!-- <div id="charts" class="tab">
        <div class="block-title">This is motivating</div>
        <div class="block">
          <p>Click on the graph for more detailled information</p>
          <div class="area-chart area-chart-with-legend"></div>
        </div>
      </div> -->

  <!-- </div>
  </div> -->
</Page>

<style>
  .grid-stats .grid > div {
    /* text-align: center; */
    padding: 5px;
    font-size: 16px;
  }

  :global(.toolbar.toolbar-bottom.tabbar) {
    background-color: unset;
  }

  :global(.calendar-day-selected .calendar-day-number) {
    background-color: violet;
  }

  :global(.page-stats .block) {
    margin: 0;
  }
</style>
