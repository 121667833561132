<script>
  import {
      Block,
      f7ready,
      Link,
      List,
      ListItem,
      Navbar,
      NavRight,
      Page,
      Popover,
      Stepper
  } from "framework7-svelte";
  import HomeButton from "../components/home-button.svelte";
  import SunriseBackground from "../components/sunrise-background.svelte";
  import { browseURL, showInstallToast } from "../js/app.ts";
  import tr, { getWelcomeMessage, splitTranslation } from "../js/i18n.ts";
  import { VIDEO_SEA_WAVES } from "../js/media.ts";
  import {
      navigateSession,
      ROUTE_SETTINGS,
      ROUTE_TIMER
  } from "../js/routes.ts";
  import {
      APP_NAME,
      getStoredValue,
      setStoredValue,
      StoreValue
  } from "../js/settings.ts";

  // Router component will receive f7router prop with current Router instance
  export let f7router;

  var lastSelectedTime = getStoredValue(StoreValue.favorite_time, 5); // default to 5m

  // get welcome mnessage
  const welcomeTitle = getWelcomeMessage(new Date().getHours());
  const [i_have_n_mins, n_mins_to] = splitTranslation(
    "home.have_time_to",
    "minutes"
  );

  function start() {
    startCustomTimeSession(lastSelectedTime);
  }

  function startSleep() {
    console.log(`home: starting sleep ${lastSelectedTime}m`);
    startCustomTimeSession(lastSelectedTime, { props: { intro: "sleep" } });
  }

  function startCustomTimeSession(minutes, props) {
    if (minutes <= 0 || minutes > 600)
      throw Error(`Invalid minutes range ${minutes}`);

    console.log(`home: starting coherence ${minutes}m`);
    navigateSession(f7router, minutes, props);
  }

  function updateCustomTimeSession(minutes) {
    if (minutes <= 0 || minutes > 600)
      throw Error(`Invalid minutes range ${minutes}`);
    setStoredValue(StoreValue.favorite_time, minutes);
    lastSelectedTime = minutes;
  }

  const formatMinutes = (min) =>
    `${min} ${tr("common.minute", { count: min }).toLowerCase()}`;

  const browseSupportPage = () =>
    browseURL(
      "https://coherence-app.com/support-us?utm_source=app&utm_content=home_heart_link"
    );

  const browseHelpPage = () =>
    browseURL(
      "https://coherence-app.com/information/tag/help?utm_source=app&utm_content=home_help_link"
    );

  const startTimer = () => {
    console.log(`home: starting meditation ${lastSelectedTime}m`);
    f7router.navigate(`${ROUTE_TIMER}${lastSelectedTime}`, {
      props: {
        bgVideoUrl: VIDEO_SEA_WAVES
        // bgImageUrl: "https://images.unsplash.com/photo-1473448912268-2022ce9509d8"
      }
    });
  };

  let installTimer;
  let installToast;

  const onPageAfterIn = () => {
    f7ready((f7) => {
      // animate welcome
      f7.$("h1.fade").animate(
        { opacity: 1 },
        { duration: 1200, easing: "swing" }
      );
      // offer user to install if needed
      installTimer = setTimeout(() => {
        installToast = showInstallToast(f7);
      }, 3000);
    });
  };

  function onPageBeforeOut() {
    // should close toast as well
    if (installTimer) {
      clearTimeout(installTimer);
      installTimer = null;
    }
    if (installToast) {
      installToast.close();
      installToast = null;
    }
  }
</script>

<Page
  pageContent={false}
  name="home"
  class="no-default-bg"
  onPageAfterIn={onPageAfterIn}
  onPageBeforeOut={onPageBeforeOut}>
  <Navbar transparent>
    <NavRight>
      <Link iconMaterial="help" onClick={browseHelpPage}></Link>
      <!-- <Link iconMaterial="share" onClick={shareApp}></Link> -->
      <Link iconMaterial="settings" href={ROUTE_SETTINGS}></Link>
    </NavRight>
  </Navbar>

  <SunriseBackground />

  <div class="container">
    <div class="messages-container">
      <Block textColor="white">
        <h1>
          {welcomeTitle},
        </h1>
      </Block>
    </div>
    <!-- messages-container -->

    <div class="buttons-container">
      <Block inset="false" textColor="white" class="time-selection">
        <h2 class="">
          {i_have_n_mins}
          <Link
            popoverOpen=".popover-menu"
            style="color: white; font-weight: bolder;  text-decoration-line: underline;"
            >{formatMinutes(lastSelectedTime)} ▼</Link>
          {n_mins_to}
        </h2>
        <Popover
          class="popover-menu"
          closeByOutsideClick={true}
          closeOnEscape={true}
          verticalPosition="bottom"
          style="width: 16.5em; opacity 0.9;">
          <List>
            <ListItem>
              <Stepper
                slot="media"
                min={1}
                max={600}
                step={1}
                value={lastSelectedTime}
                small
                onStepperChange={updateCustomTimeSession} />
              <span slot="after">minutes</span>
            </ListItem>
            {#each [5, 10, 15, 20, 25, 30] as timeMin}
              <ListItem
                link={"#"}
                popoverClose
                title={formatMinutes(timeMin)}
                on:click={() => updateCustomTimeSession(timeMin)} />
            {/each}
          </List>
        </Popover>
      </Block>

      <Block smallInset="true" class="tiles" ref="buttons-block">
        <div class="grid grid-cols-3 grid-gap">
          <HomeButton
            icon="hotel"
            text={tr("common.sleep")}
            iconColor="primary"
            on:click={startSleep} />
          <HomeButton
            icon="wb_twilight"
            text={APP_NAME}
            iconColor="deeporange"
            on:click={start} />
          <HomeButton
            on:click={startTimer}
            icon="self_improvement"
            text={tr("common.meditate")}
            iconColor="lightblue" />
        </div>
      </Block>
    </div>
    <!-- buttons-container -->
  </div>
  <!-- container -->
</Page>

<style>
  :root {
    --f7-block-margin-vertical: 16px;
    --f7-block-inset-side-margin: 0px;
  }

  h1 {
    font-size: clamp(1.8em, 4vw, 3em);
    line-height: 1.1;
    font-optical-sizing: auto;
    font-weight: bold;
  }

  h2 {
    font-size: clamp(1.5em, 2vw, 2.5em);
    font-optical-sizing: auto;
    font-weight: bold;
  }

  .container {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .messages-container {
    position: fixed;
    top: 18%;
    width: 100%;
    z-index: 42;
  }

  .buttons-container {
    position: fixed;
    top: 25%;
    width: 100%;
    z-index: 42;
  }

  @media (min-width: 768px) {
    .messages-container {
      width: 70%;
      margin-left: 16%;
    }

    .buttons-container {
      width: 70%;
      margin-left: 16%;
    }
  }

  /* svelte trick to ref in css */
  :global([ref="buttons-block"]) {
    /* padding-top: 34%; */
  }

  /* not sure why the Button component needs global here*/
  :global(.button-extra) {
    background-color: rgba(255, 255, 255, 0.5);
  }
</style>
