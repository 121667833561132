<script lang="ts">
  import {
    f7,
    f7ready,
    Fab,
    FabButton,
    FabButtons,
    Icon,
    Link,
    Page
  } from "framework7-svelte";
  import { onDestroy, onMount } from "svelte";
  import SessionFinishPage from "../components/session-finish-page.svelte";
  import {
    recordEventSessionEnd,
    recordEventSessionStart
  } from "../js/analytics.ts";
  import { dispatch, EVENT } from "../js/app.ts";
  import { translate as tr } from "../js/i18n.ts";
  import {
    CHIMES_1,
    DriftCorrectingTimer,
    MediaPlayer,
    Timer
  } from "../js/media.ts";
  import { navigateHome } from "../js/routes.ts";
  import { addSessionStats, SessionType } from "../js/settings.ts";

  export let f7router;
  export let duration = 5;
  export let bgAudioUrl = null;
  export let endAudioUrl = CHIMES_1;
  export let bgImageUrl = null;
  export let bgVideoUrl = null;
  export const textColor = "white"; // TODO: allow custom text color

  const DEFAULT_TIMER_TEXT = "00:00";
  let timerText = DEFAULT_TIMER_TEXT;
  let timerTextEl: HTMLElement;
  let timerExtraTextEl: HTMLElement;
  let timerExtraText = "";
  let timerElapsedText = "";
  let timerElapsedSecs = 0;
  let finishPageEl: SessionFinishPage;
  let videoEl: HTMLVideoElement;
  let timerFinished = false;
  let addExtraTime = false;
  let extraTimeIconEl: Icon;
  $: extraTimeIcon = "add_circle";

  const finishedMediaPlayer = endAudioUrl
    ? new MediaPlayer(endAudioUrl, false)
    : null; // not looping
  const bgMediaPlayer = bgAudioUrl ? new MediaPlayer(bgAudioUrl, true) : null; // looping

  const timer = new Timer("meditation_timer", duration * 60 * 1000);

  const onTimerFinished = () => {
    timerFinished = true;
    bgMediaPlayer?.stop();
    finishedMediaPlayer?.start();

    // update display again
    onTimerTick();
    // show extra time
    animateAppear(timerExtraTextEl);
  };

  const onTimerTick = () => {
    let text = DEFAULT_TIMER_TEXT;
    if (timer.isFinished()) {
      if (!timerFinished) {
        // update local state
        onTimerFinished(); // call only once
      }
      timerExtraText = timer.getRemainingText();
    } else if (timer.isStarted()) {
      text = timer.getRemainingText();
    }
    timerText = text;

    // svelte reactivity update
    timerElapsedText = timer.getElapsedText();
  };

  const elapsedTimer = new DriftCorrectingTimer(onTimerTick, 100); // update every 100ms

  const start = () => {
    timer.start();
    elapsedTimer.start();
    bgMediaPlayer?.start();

    // manual loop implementation to avoid default loop stutter
    // can be null when no video displayed
    videoEl?.addEventListener(
      "ended",
      function () {
        if (!videoEl) return;
        videoEl.currentTime = 0;
        videoEl.play();
      },
      false
    );

    // animate appear timer
    animateAppear(timerTextEl);

    // let listeners know we're starting a session
    dispatch(EVENT.sessionStateChange, true);
    // analytics
    recordEventSessionStart(duration);

    // document.querySelector("video").playbackRate = 0.6;
  };

  const animateAppear = (elem: HTMLElement) => {
    const el = f7.$(elem);
    el.animate(
      { opacity: 1 },
      {
        duration: 500,
        easing: "swing"
      }
    );
  };

  const togglePause = () => {
    if (timer.isPaused()) {
      timer.resume();
      videoEl?.play();
    } else {
      timer.pause();
      videoEl?.pause();
    }
  };

  const toggleAddExtraTime = () => {
    addExtraTime = !addExtraTime;
    extraTimeIcon = addExtraTime ? "do_not_disturb_on" : "add_circle";
  };

  const showFinishPage = () => {
    const elapsedSecs = getElapsedTimeSecs();
    if (!elapsedSecs) return;
    // svelte finish page state update
    timerElapsedSecs = elapsedSecs;
    finishPageEl.open();
  };

  const getElapsedTimeSecs = () => {
    // use ceil to match the elapsed timer
    let elapsedSecs = Math.ceil(timer.getElapsedTimeMs() / 1000);
    const durationSecs = duration * 60;
    if (elapsedSecs > durationSecs && !addExtraTime) {
      elapsedSecs = durationSecs; // max out at timer initial timer
    }

    return elapsedSecs;
  };

  const returnHome = (elapsedSecs: number) => {
    // let listeners know we're ending a session
    dispatch(EVENT.sessionStateChange, false);
    // analytics
    recordEventSessionEnd(elapsedSecs / 60); // record minutes
    // go home
    navigateHome(f7router);
  };

  const onFinish = () => {
    const elapsedSecs = getElapsedTimeSecs();
    const startTimeNum = timer.getStartTime();
    if (!elapsedSecs || !startTimeNum) return;

    // store stats
    addSessionStats({
      date: startTimeNum,
      elapsed: elapsedSecs,
      type: SessionType.meditation
    });

    returnHome(elapsedSecs);
  };

  const onDiscard = () => {
    const elapsedSecs = getElapsedTimeSecs();
    returnHome(elapsedSecs);
  };

  onDestroy(() => {
    elapsedTimer.stop();
    bgMediaPlayer?.stop();
    finishedMediaPlayer?.stop();
  });

  onMount(() => f7ready(start));
</script>

<Page name="timer" class="timer-bg no-default-bg">
  <div class="background">
    {#if bgVideoUrl}
      <video autoplay muted playsinline bind:this={videoEl}>
        <source src={bgVideoUrl} type="video/mp4" />
        Sorry, there was a problem loading the background animation. please retry
      </video>
    {/if}

    {#if bgImageUrl}
      <div class="bgimage" style="background-image: url({bgImageUrl});"></div>
    {/if}
  </div>

  <div class="container">
    <div class="container-content">
      <h1 bind:this={timerTextEl}>{timerText}</h1>
      <div class="extra-time" bind:this={timerExtraTextEl}>
        <span class="extra-time-add-text">
          {addExtraTime ? tr("common.added") : tr("common.add")}
        </span>
        <span class="extra-time-text" style="padding-left: 5px;">
          {timerExtraText}
        </span>
        <Link onClick={toggleAddExtraTime}>
          <Icon
            bind:this={extraTimeIconEl}
            material={extraTimeIcon}
            color="white"
            style="display: inline; padding-left: 5px;"></Icon>
        </Link>
      </div>
      <!-- <p>Meditation</p> -->
    </div>

    <Fab position="center-center" onClick={togglePause}>
      <Icon material="pause" color="white" />
      <Icon material="play_arrow" color="white" />
      <FabButtons position="bottom">
        <FabButton fabClose onClick={showFinishPage}
          ><Icon material="stop" color="white" /></FabButton>
      </FabButtons>
    </Fab>
  </div>

  <SessionFinishPage
    f7router={f7router}
    bind:this={finishPageEl}
    completedTimeSecs={timerElapsedSecs}
    onContinuePressed={togglePause}
    onFinishPressed={onFinish}
    onDiscardPressed={onDiscard} />
</Page>

<style>
  :global(.fab > a, .fab-buttons > a) {
    background-color: unset;
  }

  :global(.fab .icon) {
    font-size: 48px;
  }

  :global(.dark .popup.transparent) {
    background: rgb(0, 0, 0, 0.5);
  }

  :global(.fab-buttons .icon) {
    font-size: 36px;
  }

  :global(.fab-center-center) {
    top: 70vh;
  }

  .extra-time {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .container-content {
    position: relative;
    top: 30vh;
    color: #fff;
    text-align: center;
  }

  .extra-time {
    opacity: 0;
  }

  .extra-time-text {
    font-size: 2em; /* fallback when no clamp support */
    font-size: clamp(16px, 4vw, 28px);
  }

  .extra-time-add-text {
    font-size: 1.5em; /* fallback when no clamp support */
    font-size: clamp(12px, 4vw, 18px);
  }

  .container h1 {
    /* font-family:
      Playfair Display,
      serif; */
    font-size: 4em; /* fallback when no clamp support */
    font-size: clamp(64px, 7vw, 136px);
    line-height: 1.1;

    font-family: "Rubik", "Roboto", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 5px;
    text-shadow: white 1px 1px 1px
  }

  .bgimage {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  :global(.timer-bg) {
    background-color: #330033;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
  }
</style>
