import { getRawStoredValue, setRawStoredValue } from "./settings";

export const round = (value: number, decimals: number) => {
    return Number(Math.round(Number(`${value}e${decimals}`)) + 'e-' + decimals);
}

function throwError(errorMessage: string): never {
    throw new Error(errorMessage);
}

export interface RandomStringGenerator {
    getNext(): string;
}

/**
 * Returns a new string every time, and avoid showing the ones already shown. 
 * Uses settings to persist data.
 */
export class PersistentRandomStringGenerator implements RandomStringGenerator {
    private readonly name: string;
    private readonly stringList: string[];
    private readonly usedIndices: Set<number>;

    constructor(name: string, stringList: string[]) {
        this.name = name ?? throwError('name cannot be null');
        this.stringList = stringList ?? throwError('stringList cannot be null');
        const storedVals = getRawStoredValue(`prsg-${this.name}`, []); // empty array by default
        this.usedIndices = new Set(storedVals);
    }

    getNext(): string {
        if (this.usedIndices.size === this.stringList.length) {
            this.reset();
        }

        let randomIndex: number;
        do {
            randomIndex = Math.floor(Math.random() * this.stringList.length);
        } while (this.usedIndices.has(randomIndex));

        this.usedIndices.add(randomIndex);
        setRawStoredValue(`prsg-${this.name}`, Array.from(this.usedIndices)); // store as array

        return this.stringList[randomIndex];
    }

    private reset(): void {
        this.usedIndices.clear();
    }
}